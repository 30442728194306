import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
    
    constructor(private translate: TranslateService) { }

    ngOnInit() {
        this.model = [
            {
                label: '',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'menu.dashboard',
                        icon: 'pi pi-fw pi-th-large',
                        routerLink: ['/dashboard']
                    },
                    {
                        label: 'menu.farms',
                        iconFamily: 'material',
                        icon: ['fas', 'wind_power'],
                        routerLink: ['/farms']
                    },
                    {
                        label: 'menu.projects',
                        iconFamily: 'fontawesome',
                        icon: ['fas', 'arrows-to-circle'],
                        routerLink: ['/projects']
                    },
                ]
            },
        ];
    }
}
