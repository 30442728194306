import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, createTranslateLoader } from './app.component';
import { AppLayoutModule } from './shared/layout/app.layout.module';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { TokenInterceptor } from './shared/auth/auth.interceptor';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { AuthService } from './shared/auth/auth-service';
import { ToastNotifierService } from './core/services/toast-notifier.service';
import { MessageService } from 'primeng/api';
import { registerLocaleData } from '@angular/common';
import * as Sentry from "@sentry/angular";

import localeEs from '@angular/common/locales/es';
import localeExtraEs from '@angular/common/locales/extra/es';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CoreModule } from './core/core.module';
import { ToastModule } from 'primeng/toast';

import { GoogleTagManagerModule } from 'angular-google-tag-manager'
import { connectFirestoreEmulator, getFirestore, initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
registerLocaleData(localeEs, 'es-ES', localeExtraEs);


Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: environment.name
});

@NgModule({
    declarations: [
        AppComponent, 
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        FontAwesomeModule,
        CoreModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        GoogleTagManagerModule.forRoot({
            id: 'GTM-PXKC2ZGB',
          })
    ],
    providers: [
        AuthService,
        ToastNotifierService,
        MessageService,
        { provide: LOCALE_ID, useValue: "es-ES" },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
        { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost', 9099] : undefined },
        { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8081] : undefined },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => {
            const app = initializeApp(environment.firebase);
            const firestore = initializeFirestore(app, {}, environment.databaseId);            
            return firestore;
          })
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary, trace: Sentry.TraceService) {
        // Add an icon to the library for convenient access in other components
        library.addIconPacks(fas, far);
    }
}
